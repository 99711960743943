.viewport-pagination-view {
    min-height: 100%;
    max-height: 100vh;
    height: 100%;
}

.viewport-pagination-view .viewport-container {
    transition: transform 1s ease;
}

.viewport-pagination-view .viewport {
    min-height: 100%;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.viewport-pagination-view .viewport > *{
    width: 100%;
}

.viewport-pagination-view .scroll-down {
    text-align: center;
    position: relative;
    top: 100px;
}

.viewport-pagination-view p, .viewport-pagination-view ol, .viewport-pagination-view h1, .viewport-pagination-view h2, .viewport-pagination-view h3, .viewport-pagination-view h4, .viewport-pagination-view h5, .viewport-pagination-view h6 {
    margin-left: 40px;
    margin-right: 40px;
}

@media (max-width: 601px) {
    .viewport-pagination-view {
        text-align: center;
    }
}