.focusable-image-carousel-tile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 980px;
}

.focusable-image-carousel-tile img {
    width: auto;
    max-width: 980px;
    height: auto;
    cursor: pointer;
}

.focusable-image-carousel-tile p {
    padding-top: 20px;
    text-align: center;
}

@media (max-height: 700px) and (max-width: 601px) {
    .focusable-image-carousel-tile p {
        display: none;
    }
}

@media (min-height: 1000px) and (max-width: 601px) {
    .focusable-image-carousel-tile img {
        max-height: 57.5vh;
    }
}

@media (min-height: 800px) and (max-height: 999px) and (max-width: 601px) {
    .focusable-image-carousel-tile img {
        max-height: 40vh;
    }
}

@media (min-height: 700px) and (max-height: 799px) and (max-width: 601px) {
    .focusable-image-carousel-tile img {
        max-height: 35vh;
    }
}

@media (min-height: 600px) and (max-height: 699px) and (max-width: 601px) {
    .focusable-image-carousel-tile img {
        max-height: 37.5vh;

    }
}

@media (min-height: 400px) and (max-height: 599px) and (max-width: 601px) {
    .focusable-image-carousel-tile img {
        max-height: 37.5vh;
    }
}

/* Desktop Rules Start */

@media  (min-width: 601px) {
    .focusable-image-carousel-tile img {
        max-height: 50vh;
    }
}