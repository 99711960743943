.theme-button {
    border: 2px solid white;
    border-radius: 20px;
    transition: border 150ms linear;
    height: 20px;
    width: 20px;
    overflow: hidden;
}

.theme-button:hover {
    border: 2px solid black;
}

.theme-button .primary-circle {
    position: relative;
    right: 33px;
    bottom: 33px;
    border-radius: 50px;
    height: 50px;
    width: 50px;
    z-index: 9;
}

.theme-button .accent-shape-1 {
    position: relative;
    left: 8px;
    bottom: 49px;
    border-radius: 5px;
    height: 10px;
    width: 10px;
    z-index: 8;
}

.theme-button .accent-shape-2 {
    position: relative;
    right: 1px;
    bottom: 56px;
    transform: rotate(-27deg);
    height: 10px;
    width: 16px;
    z-index: 8;
}