.theme-picker {
    height: 40px;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 200;
    opacity: 0.5;
    transition: transform 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 300ms, opacity 250ms ease-in-out;
    transform: translateX(120px);
}

.theme-picker:hover {
    transform: translateX(0);
    opacity: 1;
}

.theme-picker .show-arrow {
    transition: transform 400ms ease-in-out 300ms;
    /* height: 24px; */
    /* width: px; */
    padding: 0 5px;
}

.theme-picker:hover .show-arrow {
    transform: rotate(180deg);
}

.theme-picker > * {
    margin: 0 8px;
}