.navBar {
    font-family: 'Karla', sans-serif;
    height: 60px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0px;
    width: 100%;
    overflow: hidden;
    z-index: 200;
    transition: transform 250ms linear;
}

.navBar .links {
    padding: 20px 0px;
    margin: 0px;
    text-align: center;
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 999;
}

.navBar .links.row-2 {
    top: 58px;
}

.navBar .links a {
    padding: 0.55em;
    font-weight: 700;
    user-select: none;
    white-space: nowrap;
}

.navBar .desktop {
    height: 60px;
}

.navBar .mobile {
    display: none;
}

.navBar .gradient-wrapper .gradient-1 {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

@media (max-width: 601px) {
    .navBar .desktop {
        display: none;
    }

    .navBar .search {
        float: initial;
        margin: auto;
    }

    .navBar .mobile {
        display: inline;
        float: none;
    }

    .navBar {
        height: 120px;
    }

    .navBar.hidden-navbar {
        /* transform: translateY(-120px); */
    }
}

@media (max-width: 401px) {
    .navBar .links a {
        padding: 0.5em;
    }
}