.top-album {
    float: left;
    display: grid;
}
.top-album .filter {
    background-image: radial-gradient(rgba(100, 100, 100, 0.3), rgba(10, 10, 10, 0.3));
    transition: opacity ease-in-out 100ms;
    opacity: 1;
    z-index: 11;
    grid-column: 1;
    grid-row: 1;
}
.top-album .filter:hover {
    opacity: 0;
}
.top-album img {
    z-index: 10;
    position: relative;
    grid-column: 1;
    grid-row: 1;
}