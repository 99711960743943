.carousel-index-indicator-item {
    border: 2px solid;
    border-radius: 10px;
    height: 10px;
    width: 10px;
    margin: 10px;
    overflow: hidden;
    transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
    opacity: 0.8;
    cursor: pointer;
}

.carousel-index-indicator-item.active {
    opacity: 1;
    transform: scale(1.4);
}

@media (max-width: 350px) {
    .carousel-index-indicator-item {
        margin: 5px;
    }
}