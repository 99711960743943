/* All CSS relating to colors should be put here so that it can easily be swapped out for a pluggable color scheme */

/* General */
.all-star .highlight-full-stop {
    color: #FF2E38;
}

.all-star {
    color: #F0F0F0;
}

.all-star .rootpage .background {
    background-image: linear-gradient(rgb(24, 49, 139), #2E5DFF);
}

.all-star .rootpage h1 {
    color: #F0F0F0;
}

/* Nav Bar Section */
.all-star .navBar .links a {
    color: #F0F0F0;
}

/* Profiles Section */
.all-star .profiles .links a {
    color: #F0F0F0;
}

.all-star a {
    color: #F0F0F0;
}

.all-star .homepage .contact-me a:hover, .all-star .projects .project-link a:hover {
    color: #2E5DFF;
    background-color: #F0F0F0;
    border-color: #F0F0F0;
}

.all-star .homepage .contact-me a, .all-star .projects .project-link a {
    color: #F0F0F0;
    background-color: none;
    border-color: #F0F0F0;
}

.all-star .navBar .gradient-wrapper .gradient-1 {
    background-image: linear-gradient(rgb(24, 49, 139), #2E5DFF);
}

.all-star .recent-track.loading-shimmer .animate {
    background-image: linear-gradient(to right, rgb(31, 67, 193) 4%, #2E5DFF 25%, rgb(31, 67, 193) 36%);
}

.all-star .recent-track .display-photo.fa > svg {
    color: #2E5DFF;
}

.all-star .lastfm-creep .recent-track {
    background-color: #274AC4;
}

.all-star .index-indicator-circle {
    border-color: #F0F0F0;
}

.all-star .index-indicator-circle.active {
    background-color: #F0F0F0;
}

.all-star .carousel-index-indicator-item {
    background-color: #F0F0F0;
}