.homepage {
    min-height: 100vh;
    height: 100vh;
    max-height: 100vh;
    display: flex;
    overflow: hidden;
    align-items: center;
}

.homepage .display-photo {
    padding-left: 20px;
    padding-right: 20px;
    display: inline-block;
    vertical-align: middle;
}

.homepage .display-photo img {
    width: 5em;
    height: 5em;
    border-radius: 2.5em;
    user-select: none;
}

.homepage .contact-me {
    padding-bottom: 5em;
}

.homepage .contact-me a {
    border: 2px solid;
    padding: 0.5em 1.5em;
    border-radius: 1.5em;
    font-size: 1.3em;
    transition: color 150ms ease-in-out, background-color 150ms ease-in-out;
}

.homepage .contact-me a:hover {
    text-decoration: none;
}

@media (max-width: 601px) {
    .homepage {
        padding-top: 120px;
        overflow-y: auto;
        overflow-x: hidden;
        display: block;
        align-items: none;
        text-align: center;
        min-height: 0;
        height: initial;
    }

    .homepage .body {
        padding-bottom: 120px;
    }

    .homepage .display-photo {
        width: 100%;
        padding: 0px;
    }

    .homepage a.contact-me {
        float: none;
    }
}