.profiles {
    display: flex;
    align-items: center;
    min-height: 100vh;
}

.profiles .body {
    width: 100%;
}

.profiles .links {
    text-align: center;
}

.profiles h1 {
    text-align: center;
    width: 100%;
    padding: 0;
    margin: 0;
}

.profiles .links a {
    font-size: 80px;
    margin: 0.5em;
    line-height: 2em;
}

@media (max-width: 601px) {
    .profiles .links a {
        font-size: 4em;
    }

    .profiles {
        text-align: center;
    }

    .profiles .body {
        position: fixed;
        height: 100%;
    }

    .profiles .body > div {
        padding-top: 120px;
    }
}

@media (max-width: 401px) {
    .profiles .links a {
        font-size: 3em;
    }
}