.carousel-index-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carousel-index-indicator .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 200;
    width: unset;
}

@media (max-width: 601px) {
    .carousel-index-indicator {
        top: 120px;
        min-height: 0;
    }
}