.music .albums-pane {
    margin: auto;
}

.music .album-text {
    padding: 0.5em;
    line-height: 30px;
    text-align: center;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
}

.music .album-text-placeholder {
    padding: 0.5em;
    line-height: 30px;
    text-align: center;
    opacity: 0.7;
    height: 90px;
}