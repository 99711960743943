.index-indicator-circle {
    border: 2px solid;
    border-radius: 20px;
    height: 20px;
    width: 20px;
    overflow: hidden;
    transition: background-color 100ms ease-in-out, transform 100ms ease-in-out;
    cursor: pointer;
}

.index-indicator-circle.active {
    transform: scale(1.2);
}